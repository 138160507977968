@import url("https://fonts.googleapis.com/css?family=Aleo");
@import "../src/Components/shared/scss/variable.scss";

.App {
  font-family: Aleo !important;
  box-sizing: border-box;
  font-size:1.8rem;
}
html {
  font-size: 62.5%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
*,
 *:before,
 *:after {
  box-sizing: inherit;
}
@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px !important;
  }
}
.green{
  color:$green;
}
.purple{
  color:$purple;
}
.red{
  color:$red;
}
.pointer{
  cursor: pointer;
}
.word-wrap{
  word-wrap:break-word;
}
